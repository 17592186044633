import types from '../types';

const initialState = {
  jwtAccessToken: '',
  workLists: false,
  auditLogList:false,
};

 const workLists = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_JWT:
      return {
        ...state,
        jwtAccessToken: action.payload,
      };
      case types.RESET_CONFIGURATOR_STATE:
        return { 
           ...initialState ,
          };
      case types.SET_WORKLIST_DATA:
        const { workLists} = action.payload;
        return {
          ...state,
          workLists,
        };
        case types.SET_AUDITLOG_DATA:
        const { auditLogList} = action.payload;
        return {
          ...state,
          auditLogList,
        };
        case types.RESET_WORKLIST_DATA:
    console.log('INSIDE WORKLIST ITEM TO REDUX reducer');
    return {
      ...state,
      workLists: false,
    };
       
    default:
      return state;
  }
};

export default workLists;
